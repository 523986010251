import _merge from 'lodash/merge';
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import en from './locales/en.json';
// import ru from './locales/ru.json';

export const determinantLanguage = navigator.language.split(/[-_]/)[0];

const resources = {
  en: {
    translation: en,
  },
  // ru: {
  //   translation: ru,
  // },
};

function init(localization) {
  const usersLng = navigator.language.split(/[-_]/)[0];

  i18next.init({
    lng: usersLng,
    fallbackLng: 'en',
    resources: _merge(resources, localization),
    react: {
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
    },
  });
}

function t(keys, options) {
  // see https://www.i18next.com/overview/api#t
  return i18next.t(keys, options);
}

const Localization = {
  init,
  t,
};

export { Localization };
export { t };
export const T = ({ k, ...other }) => <Trans i18n={i18next} i18nKey={k} {...other} />;

T.propTypes = {
  k: PropTypes.string.isRequired,
};
