import {
  RESET,
  SET_IS_SUBMIT_BY_CARD,
  SET_LOADING,
  SET_PURCHASE,
  SET_CUSTOMER,
  SET_ORDER_DETAILS,
  SET_ORDER_PENDING,
  SET_PRODUCTS,
  SET_DISCOUNT_END_DATE,
  SET_SPECIAL_OFFER_PRODUCT,
  SET_SPECIAL_OFFER_END_DATE,
  SET_PENDING,
} from './types';

export const initialState = {
  purchase: null,
  purchased: false,
  loading: true,
  pending: false,
  isSubmitByCard: false,
  customer: null,
  orderDetails: null,
  orderPending: false,
  products: [],
  discountEndDate: null,
  specialOfferProduct: null,
  specialOfferEndDate: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PURCHASE:
      return {
        ...state,
        purchase: action.payload.purchase,
        purchased: !!action.payload.purchase,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case SET_IS_SUBMIT_BY_CARD:
      return {
        ...state,
        isSubmitByCard: action.payload,
      };

    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };

    case SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case SET_ORDER_PENDING:
      return {
        ...state,
        orderPending: action.payload,
      };

    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case SET_DISCOUNT_END_DATE:
      return {
        ...state,
        discountEndDate: action.payload,
      };

    case SET_SPECIAL_OFFER_END_DATE:
      return {
        ...state,
        specialOfferEndDate: action.payload,
      };

    case SET_SPECIAL_OFFER_PRODUCT:
      return {
        ...state,
        specialOfferProduct: action.payload,
      };

    case RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

reducer.whitelist = ['subscriptionDetails', 'purchased', 'discountEndDate'];

export default reducer;
