import { SET_SELECTED_QUIZ_ANSWERS } from './types';

const initialState = {
  selectedQuizAnswers: [],
};

const quiz = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_QUIZ_ANSWERS:
      return {
        ...state,
        selectedQuizAnswers: action.payload,
      };
    default:
      return state;
  }
};

export default quiz;
