import * as TYPES from './types';

export const initialState = {
  authorized: false,
  email: '',
  gender: '',
  improvements: [],
  age: 0,
  correctAnswers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_AUTHORIZED:
      return {
        ...state,
        authorized: true,
      };
    case TYPES.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case TYPES.RESET_EMAIL:
      return {
        ...state,
        email: initialState.email,
      };
    case TYPES.SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case TYPES.SET_AGE:
      return {
        ...state,
        age: action.payload,
      };
    case TYPES.SET_IMPROVEMENTS:
      return {
        ...state,
        improvements: action.payload,
      };
    case TYPES.SET_CORRECT_ANSWERS:
      return {
        ...state,
        correctAnswers: action.payload,
      };
    default:
      return state;
  }
};

reducer.blacklist = ['authorized'];

export default reducer;
