
import { PROJECTS_TYPES } from 'core/constants/general';

import {
  SET_PROJECT_TYPE,
  SET_LOADED,
  SET_PENDING,
} from './types';

export const initialState = {
  projectType: PROJECTS_TYPES.FLOW,
  loaded: false,
  pending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_TYPE:
      return {
        ...state,
        projectType: action.payload,
      };

    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    default:
      return state;
  }
};

reducer.blacklist = ['loaded', 'pending'];

export default reducer;
