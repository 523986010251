import { SET_EMAIL, SET_PENDING } from './types';

export const initialState = {
  email: null,
  pending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    default:
      return state;
  }
};
