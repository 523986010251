export const PREFIX_WITH_PAYMENT = '-pp';

export const PROJECTS_TYPES = {
  QUIZ: 'quiz',
  QUIZ_PP: `quiz${PREFIX_WITH_PAYMENT}`,
  TQUIZ: 'tquiz',
  TQUIZ_PP: `tquiz${PREFIX_WITH_PAYMENT}`,
  FLOW: 'flow',
  FLOW_PP: `flow${PREFIX_WITH_PAYMENT}`,
  LANDING: 'landing',
  TERMINATE: 'terminate',
};
